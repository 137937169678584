import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const PrivacyStyles = styled.div`
  @mixin break($p, $b: 'max-width') {
    @media screen and ($b: $p + px) {
      @content;
    }
  }
  #privacy {
    // height: 100vh;
    position: relative;
    display: block;
    padding: 40px 0 120px;
    background-color: var(--white);
    h2,
    h6 {
      text-align: center;
      @media screen and (max-width: 650px) {
        font-size: 38px;
      }
    }
    h6 {
      font-size: 2.5rem;
      line-height: 1.2;
      max-width: 900px;
      margin: 40px auto 20px;
      text-align: left;
      color: #474747;
    }
    p {
      text-align: left;
      max-width: 900px;
      margin: 0 auto;
      &:last-of-type {
        margin-bottom: 25px;
      }
      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 768px) {
      display: block;
    }
    @media screen and (max-width: 650px) {
      padding: 30px 0 70px;
    }
    a {
      font-size: 2rem;
      border-bottom: 1px solid #474747;
      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    .to-home {
      text-align: center;
      display: block;
      padding: 20px 0 0;
      a.btn {
        font-size: 2.2rem;
        color: white;
        border: 0;
        padding: 15px 20px;
        background: var(--gold);
        @media screen and (max-width: 650px) {
          /* font-size: 18px; */
        }
      }
    }
  }
`;

export default function PrivacyPage() {
  return (
    <>
      <SEO title="SGM | Privacy Policy" />
      <PrivacyStyles>
        <section id="privacy">
          <div className="wrap">
            <h2>Privacy Notice</h2>

            <p>
              Protecting your private information is incredibly important for
              Stephen Gerber Music. This statement of privacy applies
              to stephengerbermusic.com, associated subdomains, and Stephen
              Gerber Music and governs data collection and usage.
            </p>
            <p>
              For the purpose of this privacy policy, unless otherwise noted,
              all references to Stephen Gerber Music include
              stephengerbermusic.com and SGM. The SGM website is an ecommerce
              site. By Using the SGM website, you consent to the data practices
              described in this statement.
            </p>

            <h6>What This Policy Applies to</h6>

            <p>
              This policy applies to information we collect: a. On the Websites;
              b. In email, text, and other electronic messages between you and
              Company; c. When you interact with our advertising and
              applications on third-party websites and services, if those
              applications or advertising include links to this Privacy Policy;
              and d. When you provide information to us when requesting
              services. This Privacy Policy also applies to information
              collected by us offline, whether in writing or orally (if
              recorded) or through any other means, including on any other
              website operated by us or any third party, or any third party,
              including through any application or content (including
              advertising) that may link to or be accessible from or on the
              Websites.
            </p>

            <h6>Collection of your Personal Information</h6>

            <p>
              We do not collect any personal information about you unless you
              voluntarily provide it to us. However, you may be required to
              provide certain personal information to us when you elect to use
              certain products or services available on the site. These may
              include sending us an email message. To wit, we will use your
              information for, but not limited to, communicating with you in
              relation to services and/or products you have requested from us.
              We also may gather additional personal or non-personal information
              in the future.
            </p>

            <h6>Sharing Information with Third Parties</h6>

            <p>
              SGM does not sell, rent or lease its customer lists to third
              parties. SGM may share data with trusted partners to help perform
              statistical analysis, send you email or postal mail, provide
              customer support, or arrange for deliveries. All such third
              parties are prohibited from using your personal information except
              to provide these services to SGM, and they are required to
              maintain the confidentiality of your information. SGM may disclose
              your personal information, without notice, if required to do so by
              law or in the good faith belief that such action is necessary to:
              (a) confirm to the edicts of the law or comply with legal process
              served on SGM or the site; (b) protect and defend the rights or
              property of SGM; and/or (c) act under exigent circumstances to
              protect the personal safety of users of SGM, or the public.
            </p>

            <h6>Tracking User Behavior</h6>

            <p>
              SGM may keep track of the websites and pages our users visit
              within SGM, in order to determine what SGM services are the most
              popular. This data is used to deliver customized content and
              advertising within SGM to customers whose behavior indicates that
              they are interested in a particular subject area.
            </p>

            <h6>Automatically Collected Information</h6>

            <p>
              Information about your computer hardware and software may be
              automatically collected by SGM. This information can include: your
              IP address, browser type, domain names, access times and referring
              website addresses. This Information is used for the operation of
              the service, to maintain quality of the service, and to provide
              general statistics regarding use of the SGM website.
            </p>

            <h6>Use of Cookies</h6>

            <p>
              The SGM website may use "cookies" to help you personalize your
              online experience. A cookie is a text file that is placed on your
              hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely
              assigned to you and can only be read by a web server in the domain
              that issued the cookie to you.One of the primary purposes of
              cookies is to provide a convenience feature to save you time. The
              purpose of a cookie is to tell the web server that you have
              returned to a specific page. For example, if you personalize SGM
              pages, or register with SGM site or services a cookie helps SGM to
              recall your specific information on subsequent visits. This
              simplifies the process of recording your personal information,
              such as billing addresses, shipping addresses, and so on. When you
              return to the same SGM website. The information you previously
              provided can be retrieved, so you can easily use the SGM features
              that you customized.You have the ability to accept or decline
              cookies. Most web browsers automatically accept cookies, but you
              can usually modify your browser setting to decline cookies if you
              prefer. If you choose to decline cookies, you may not be able to
              fully experience the interactive features of the SGM services or
              websites you visit.
            </p>

            <h6>Links</h6>
            <p>
              This website contains links to other sites. Please be aware that
              we are not responsible for the content or privacy practices of
              such other sites. We encourage our users to be aware when they
              leave our site and to read the privacy statements of any other
              site that collects personally identifiable information.
            </p>

            <h6>Children Under Thirteen</h6>

            <p>
              SGM does not knowingly collect personally identifiable information
              from children under the age of thirteen. If you are under the age
              of thirteen, you must ask your parent or guardian for permission
              to use this website.
            </p>

            <h6>External Data Storage Sites</h6>

            <p>
              We may store your data on servers provided by third party hosting
              vendors with whom we have contracted.
            </p>

            <h6>Changes to this Statement</h6>

            <p>
              SGM reserves the right to change this Privacy Policy from time to
              time. We will notify you about significant changes in the way we
              treat personal information by sending a notice to the primary
              email address specified in your account, by placing a prominent
              notice on our site, and/or by updating any privacy information on
              this page. Your continued use of the Site and/or Services
              available through this Site after such modifications will
              constitute your: (a) acknowledgment of the modified Privacy
              Policy; and (b) agreement to abide and be bound by that Policy.
            </p>

            <h6>Contact Information</h6>

            <p>
              SGM welcomes your questions or comments regarding this Statement
              of Privacy, If you believe that SGM has not adhered to this
              Statement, please contact SGM:
              <p>
                Email Address:{' '}
                <a href="mailto:hello@stephengerbermusic.com" target="_blank">
                  hello@stephengerbermusic.com
                </a>
              </p>
            </p>
            <div className="to-home">
              <Link className="btn" to="/">
                Return Home
              </Link>
            </div>
          </div>
        </section>
      </PrivacyStyles>
      <Footer />
    </>
  );
}
